export interface SkipLink {
  anchor: string
  name: string
}

export const CONTENT: SkipLink = {
  anchor: '#content',
  name: 'Contenu'
}

export const FOOTER: SkipLink = {
  anchor: '#footer',
  name: 'Pied de page'
}
