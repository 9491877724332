{
  "tenant": "Locataire",
  "owner": "Propriétaire",
  "register-information": "Afin de créer votre compte, nous avons besoin de quelques informations. Vous êtes…",
  "register.email-not-valid": "Cet email est invalide",
  "faq": "FAQ",
  "help": "Aide",
  "blog": "Blog",
  "blog-title": "DossierFacile en articles",
  "information": "Qui sommes-nous ?",
  "contact-us": "Nous contacter",
  "button": "Monter mon dossier de location",
  "docs": "Comment préparer mon dossier de location",
  "copy-success": "Le lien a été copié dans le presse-papier",
  "all-partners": "Voir tous les partenaires",
  "partners": {
    "menu": "Partenaires",
    "title": "Partenaires de DossierFacile"
  },
  "s0": {
    "title": "Montez un dossier de location en béton pour trouver le logement de vos rêves",
    "text": "DossierFacile vous aide à constituer un dossier de location numérique ",
    "text2": "de qualité pour mettre toutes les chances de votre côté."
  },
  "s1": {
    "h1": "Démarquez-vous grâce à un dossier de qualité",
    "p1": "Transmettez aux propriétaires un dossier clair, complet et cohérent pour maximiser vos chances.",
    "h2": "Soyez le premier à répondre à une annonce",
    "p2": "Soyez le plus réactif en envoyant en un clic votre dossier numérique.",
    "h3": "Rassurez votre futur·e propriétaire",
    "p3": "Après une batterie de tests par nos opérateurs, votre dossier est labellisé par l'État."
  },
  "s2": {
    "title": "Constituez votre dossier de location en trois étapes",
    "h1": "Je télécharge mes pièces",
    "p1": "DossierFacile vous demande les pièces nécessaires et rien que celles-là !",
    "u1a": "Pièce d'identité",
    "u1b": "Justificatif de situation d'hébergement",
    "u1c": "Justificatif de situation professionnelle",
    "u1d": "Avis d'imposition",
    "u1e": "Justificatif de ressources",
    "h2": "DossierFacile valide mon dossier",
    "p2": "Nos agents vérifient votre dossier et vous aident à le corriger si besoin.",
    "h3": "Mon dossier est prêt à être envoyé",
    "p3": "Votre dossier sécurisé sous forme de lien URL ou au format PDF est prêt à être envoyé aux propriétaires et aux agences."
  },
  "s3": {
    "title": "Protégez vos informations personnelles",
    "text1": "Avec DossierFacile, plus de papiers d'identité éparpillés dans la nature !",
    "text2": "Vos documents sont recouverts de filigranes pour vous protéger contre la fraude de propriétaires peu scrupuleux.",
    "text3": "Vous pouvez paramétrer l'ouverture de votre lien dossier : vous pouvez le supprimer à tout moment !",
    "link": "En savoir plus sur la sécurité des données chez DossierFacile"
  },
  "s4": {
    "title1": "Déjà",
    "title3": "Dossiers labellisés grâce à DossierFacile",
    "t1": "Le dossier de Karim s'est tout de suite démarqué : c'était le seul dossier reçu complet !",
    "author1": "Joseph, propriétaire à Nantes",
    "t2": "Simple, efficace et pratique : ça m'a fait gagner beaucoup de temps dans ma recherche d'appartement.",
    "author2": "Caroline, étudiante",
    "t3": "Je ne réussissais jamais à visiter un appartement avec mon dossier étranger. Avec DossierFacile, j'ai enfin eu plus de visites !",
    "author3": "Irina, étudiante en Erasmus",
    "link": "Consulter nos statistiques"
  },
  "s5": {
    "title1": "Ils nous font",
    "title2": "confiance"
  },
  "s6": {
    "title": "Nos partenaires institutionnels"
  },
  "title": "Statistiques",
  "404": {
    "title": "Page non trouvée",
    "subtitle": "Erreur 404",
    "caption": "La page que vous cherchez est introuvable. Veuillez nous excuser pour la gêne occasionnée.",
    "content": {
      "line1": "Si vous avez tapé l'adresse web dans le navigateur, vérifiez qu'elle est correcte. La page n’est peut-être plus disponible.",
      "line2": "Dans ce cas, pour continuer votre visite vous pouvez consulter notre page d’accueil.",
      "line3": "Sinon contactez-nous pour que l’on puisse vous rediriger vers la bonne information."
    },
    "homepage": "Page d'accueil",
    "contact-us": "Contactez-nous"
  },
  "modal": {
    "close": "Fermer",
    "close-alt": "Fermer la fenêtre modale"
  },
  "contact": {
    "title": "Comment pouvons-nous vous aider ?"
  }
}
