<script setup lang="ts"></script>

<template>
  <div class="fr-follow">
    <div class="fr-container">
      <div class="fr-grid-row">
        <div class="fr-col-12">
          <div class="fr-follow__social">
            <h2 class="fr-h5">Suivez-nous sur les réseaux sociaux</h2>
            <ul class="fr-btns-group">
              <li>
                <a
                  class="fr-btn--twitter fr-btn"
                  title="Suivre Dossier facile sur twitter - Ouvre une nouvelle fenêtre"
                  href="https://twitter.com/dossierfacile/"
                  target="_blank"
                >
                  Suivre Dossier facile sur twitter
                </a>
              </li>
              <li>
                <a
                  class="fr-btn--facebook fr-btn"
                  title="Suivre Dossier facile sur facebook - Ouvre une nouvelle fenêtre"
                  href="https://www.facebook.com/DossierFacile/"
                  target="_blank"
                >
                  Suivre Dossier facile sur facebook
                </a>
              </li>
              <li>
                <a
                  class="fr-btn--linkedin fr-btn"
                  lang="en"
                  title="Suivre Dossier facile sur linkedin - Ouvre une nouvelle fenêtre"
                  href="https://www.linkedin.com/company/startup-d-etat-locatio/"
                  target="_blank"
                >
                  Suivre Dossier facile sur linkedin
                </a>
              </li>
              <li>
                <a
                  class="fr-btn--instagram fr-btn"
                  title="Suivre Dossier facile sur instagram - Ouvre une nouvelle fenêtre"
                  href="https://www.instagram.com/dossierfacile/"
                  target="_blank"
                >
                  Suivre Dossier facile sur instagram
                </a>
              </li>
              <li>
                <a
                  class="fr-btn--tiktok fr-btn"
                  title="Suivre Dossier facile sur tiktok - Ouvre une nouvelle fenêtre"
                  href="https://www.tiktok.com/@dossierfacile/"
                  target="_blank"
                >
                  Suivre Dossier facile sur tiktok
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
